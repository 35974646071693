<template>
  <div class="information_content" v-if="load">
    <div class="information_content_layout">
      <div class="main">
        <!-- 标题 -->
        <div class="title">
          <p>
            {{index+1}}、{{allData[index].title}}
            <span v-if="allData[index].type == 2 ">(单选)</span>
            <span v-if="allData[index].type == 3 ">(多选)</span>
          </p>
        </div>
        <!-- 内容 -->
        <div class="content">
          <!-- 文本 -->
          <div class="item" v-if="allData[index].type == 1 ">
            <el-input type="textarea" :rows="8" placeholder="请输入内容" v-model="answer[index].answer"></el-input>
          </div>
          <!-- 单选 -->
          <div class="item TH-radio" v-if="allData[index].type == 2 ">
            <el-radio
              v-for="(item,key) in allData[index].preset_options.split(' ')"
              :key="key"
              v-model="answer[index].answer"
              :label="item"
              border
              size="medium"
            >{{item}}</el-radio>
          </div>
          <!-- 多选 -->
          <div class="item TH-checkbox" v-if="allData[index].type == 3 ">
            <!-- v-for="(item,key) in allData[index].preset_options.split(' ')" -->
            <el-checkbox-group v-model="answer[index].answer">
              <el-checkbox
                :label="item"
                v-for="(item,key) in allData[index].preset_options.split(' ')"
                :key="key"
              >{{item}}</el-checkbox>
            </el-checkbox-group>
          </div>
          <!-- 下拉 -->
          <div class="item" v-if="allData[index].type == 4 ">
            <el-select v-model="answer[index].answer" placeholder="请选择">
              <el-option
                v-for="(item,key) in allData[index].preset_options.split(' ')"
                :key="key"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="btn">
        <el-button type="primary" @click="last()">上一题</el-button>
        <el-button type="primary" @click="next()">下一题</el-button>
        <el-button type="primary" @click="submit()" v-if="index == allData.length-1">提交</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { answerInterview, sbmInterview } from "@/api/answer.js";
export default {
  data() {
    return {
      load: false,
      inforId: "",
      allData: [], // 所有数据
      index: 0, //下标
      answer: [], //答案保存
      test: []
    };
  },
  created() {
    this.inforId = this.$route.query.id;
    this.initInterview();
  },
  methods: {
    //   加载晤谈内容
    initInterview() {
      answerInterview({ id: this.inforId }).then(res => {
        console.log(res);
        if (res.code == 400200) {
          this.allData = res.data;
          this.load = true;
          // 数据处理
          this.allData.forEach((r, k) => {
            let obj = {
              answer: "",
              question_id: r.id
            };
            if (r.type == 3) {
              obj.answer = [];
            }
            this.answer.push(obj);
          });
        }
      });
    },
    // 下一题
    next() {
      // 判断是否答题
      if (
        this.answer[this.index].answer == "" ||
        this.answer[this.index].answer == []
      ) {
        this.$message({
          showClose: true,
          message: "请当前题目，才能进入下一题",
          type: "warning"
        });
        return;
      }
      // 是否结束
      if (this.index >= this.allData.length - 1) {
        return;
      }
      // 判断是否跳提
      if (this.allData[this.index].jump_info != "") {
        let indexTmp = this.index;
        let jump = this.allData[this.index].jump_info.split(" ");
        // 找到 答案 与 跳题 相对于的 下标
        let str = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
        let answerIndex = this.answer[this.index].answer.split(":")[0];
        let index = str.indexOf(answerIndex);
        let jumpIndex = jump[index].split(":")[1];
        this.index = jumpIndex * 1 - 1;
        // ********************************
        // 给跳过来的题目打上返回标记
        this.allData[this.index].jumpIndex = indexTmp;
        return;
      }
      this.index++;
    },
    // 上一题
    last() {
      if (this.index <= 0) {
        return;
      }
      // 判断是否跳提
      if (this.allData[this.index].jumpIndex != undefined) {
        this.index = this.allData[this.index].jumpIndex;
        return;
      }

      this.index--;
    },
    // 完成提交
    submit() {
      // 数据封装
      let data = {
        interview_id: this.inforId,
        user_interview_id: this.$route.query.uid,
        question: JSON.parse(JSON.stringify(this.answer))
      };
      // 转字符串
      for (let i = 0; i < data.question.length; i++) {
        data.question[i].answer = data.question[i].answer + "";
      }
      // 发送请求
      sbmInterview(data).then(res => {
        if (res.code == 400200) {
          this.$message({
            showClose: true,
            message: "提交成功！",
            type: "success"
          });
          this.$router.go(-1);
        }
      });
    }
  }
};
</script>
<style lang="less">
.information_content {
  .information_content_layout {
    width: 100%;
    min-width: 320px;
    max-width: 812px;
    margin: 0 auto;
  }
  .title {
    font-size: 28px;
    color: rgb(0, 94, 182);
    padding: 40px 0;
  }
  .content {
    min-height: 500px;
    .item {
    }
    //单选框
    .TH-radio {
      display: flex;
      flex-direction: column;
      label {
        margin: 10px 0;
      }
      .el-radio {
        margin-right: 0px;
        white-space: normal;
      }
      .el-radio.is-bordered + .el-radio.is-bordered {
        margin-left: 0px;
      }
      .el-radio--medium.is-bordered,
      .el-radio.is-bordered {
        height: auto;
      }
      .el-radio--medium.is-bordered {
        padding: 10px 20px 10px 10px;
      }
    }
    // 多选
    .TH-checkbox {
      .el-checkbox-group {
        display: flex;
        flex-direction: column;
        .el-checkbox {
          white-space: normal;
          padding: 10px 0;
          > .el-checkbox__input {
            display: inline;
          }
          > .el-checkbox__label {
            display: inline;
            padding-left: 20px;
          }
        }
      }
      .el-checkbox__inner {
        width: 18px;
        height: 18px;
        &::after {
          height: 11px;
          left: 5px;
          top: 0px;
          width: 5px;
        }
      }
    }
  }
  .btn {
    padding: 30px 0;
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  .information_content {
    .information_content_layout {
      width: 100%;
      min-width: 320px;
      max-width: 812px;
      margin: 0 auto;
    }
    .main {
      height: calc(~"100vh - 130px");
      overflow: auto;
      .title {
        font-size: 18px;
        color: rgb(0, 94, 182);
        padding: 10px 10px;
        border-bottom: 2px solid rgb(62, 120, 175);
      }
      .content {
        padding-top: 10px;
        min-height: 100px;
        .item {
          padding: 0 10px;
        }
      }
    }
    .btn {
      padding: 10px 0;
      display: flex;
      justify-content: center;
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>